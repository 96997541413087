import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { getServerErrorText } from 'shared/lib';
import { ErrorMessage, EmptyData } from 'shared/ui';

import { useGetOwnersQuery } from 'entities/owner';

import { OwnersReport } from './report';

export function ConnectedOwnersReport() {
  const [skip, setSkip] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    data: owners,
    isLoading: isOwnersLoading,
    error: ownersError,
  } = useGetOwnersQuery();

  const apiResponseError = [owners].find((el) => el && el.statusCode !== 0);

  if (isOwnersLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusDescription} />;
  }

  const error = [ownersError].find((err) => err !== undefined);

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!owners) {
    return <EmptyData />;
  }

  return <OwnersReport owners={owners.owners} />;
}
