import { ADD_PREFIX } from './common';

export const REPORTS = 'reports';
export const ACQUIRING = 'acquiring';
export const CONSUME = 'consume';
export const PAYMENTS = 'payments';
export const DEBIT = 'debit';
export const OWNERS = 'owners';

export const REPORT_ROUTES = {
  REPORTS: `/${REPORTS}`,
};
