import { MoreOutlined } from '@ant-design/icons';
import NiceModal from '@ebay/nice-modal-react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { UiPopover } from 'shared/ui';
import { PERSON_ROUTES } from 'shared/consts/routes/person';

import {
  checkControllerRight,
  selectTokenClaims,
  selectTokenRoles,
} from 'entities/authorization';
import { GroupDto } from 'entities/group';

import { DeleteGroupModal } from '../../delete/ui';
import {
  CHARGE_POINTS,
  CHARGE_POINT_ROUTES,
} from 'shared/consts/routes/charge-point';
import { GROUPS } from 'shared/consts/routes/common';

type Props = {
  group: GroupDto;
};

export function GroupTableActions({ group }: Props) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasRight = checkControllerRight('Group', 'Write', roles, claims);

  const navigate = useNavigate();
  const location = useLocation();

  const showDeleteModal = () => {
    NiceModal.show(DeleteGroupModal, { group });
  };

  const content = (
    <>
      <button
        onClick={() => {
          const path = location.pathname.includes(CHARGE_POINTS)
            ? `${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${GROUPS}`
            : PERSON_ROUTES.PERSONS_LIST;

          navigate(`${path}/${group.id}`);
        }}
      >
        Редактировать
      </button>
      {hasRight && <button onClick={showDeleteModal}>Удалить</button>}
    </>
  );

  return (
    <UiPopover content={content}>
      <MoreOutlined rotate={90} />
    </UiPopover>
  );
}
