import { Spin } from 'antd';
import { StyledButton } from '../../../../common-styles';

import { ReportButtonCotainer } from './styles';

import { file } from './assets';
import { openErrorNotification } from 'shared/lib';

interface GetReportButtonProps {
  disabled: boolean;
  spinning: boolean;
}

const REPORT_EXPORT_ERROR = 'Не удалось экспортировать отчет';

export function GetReportButton({ disabled, spinning }: GetReportButtonProps) {
  const download = () => {
    try {
      // const fileType =
      //   'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      const FILE_EXTENSION = '.docx';

      const blob = new Blob([file]);

      const fileName = `Реестр_платежей${FILE_EXTENSION}`;

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    } catch {
      openErrorNotification(REPORT_EXPORT_ERROR);
    }
  };

  return (
    <ReportButtonCotainer>
      <Spin spinning={spinning}>
        <StyledButton
          htmlType="button"
          type="primary"
          disabled={disabled}
          onClick={download}
        >
          Скачать отчет
        </StyledButton>
      </Spin>
    </ReportButtonCotainer>
  );
}
