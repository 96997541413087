import styled from 'styled-components';

import { COLOR, DEFAULT_BORDER } from 'shared/consts';

export const TabPanelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 42px;
  border-bottom: ${DEFAULT_BORDER};
  background-color: ${COLOR.white};
  padding-right: 20px;
  gap: 10px;

  position: sticky;
  top: 0;
  z-index: 1000;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const TabPanelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TabPanelsMenu = styled.ul`
  display: flex;
  min-width: max-content;

  & .link {
    padding: 12px 20px;
    display: flex;
    align-items: center;

    & a {
      font-size: 14px;
      line-height: 17px;
      font-weight: 400;
      color: ${COLOR.darkGrey};
    }
  }

  & .active-link {
    border-bottom: 2px solid ${COLOR.greenMenu};

    & a {
      font-weight: 500;
      color: ${COLOR.black};
    }
  }
`;

export const TabPanelItem = styled.li``;

export const LinkWrapper = styled.div`
  padding: 12px 20px;
  display: flex;
  align-items: center;

  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);

  & > a {
    color: inherit;
  }
`;
