import { OwnerDto } from 'entities/owner';
import { OwnersReportForm } from './form';

type Props = {
  owners: OwnerDto[];
};

export function OwnersReport({ owners }: Props) {
  return (
    <>
      <OwnersReportForm owners={owners} loading={false} />
    </>
  );
}
