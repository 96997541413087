import { LinkWrapper } from './styles';

const FAKE_TABS = [
  {
    key: 'аналитика',
    path: 'https://pbi.sitronics.com/Reports/powerbi/ElectroReports/SessionsReport_new',
    label: 'Аналитика',
  },
  {
    key: 'поддержка',
    path: 'https://tac.nvg.ru/arsys/shared/login.jsp?/arsys/forms/remedy/HPD:Help+Desk/Default+User+View/?cacheid=f4e2d504',
    label: 'HelpDesk',
  },
  {
    key: 'видео',
    path: 'https://tezs.sitronics.com/cslogs/',
    label: 'Видео',
  },
];

export function FakeTabs() {
  return (
    <>
      {FAKE_TABS.map((el) => {
        return (
          <li key={el.key}>
            <LinkWrapper>
              <a href={el.path} target="_blank">
                {el.label}
              </a>
            </LinkWrapper>
          </li>
        );
      })}
    </>
  );
}
