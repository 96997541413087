import { Col, Form } from 'antd';

import { FormItem, FormRow } from '../../../common-styles';
import { VALIDATION_RULE } from '../../../constants/validation-rules';

import { ReportFormWrapper } from 'entities/report';
import { UiSelect } from 'shared/ui/ui-kit';
import { OwnerDto } from 'entities/owner';
import { GetReportButton } from './button';
import { useWatch } from 'antd/lib/form/Form';

interface OwnerReportFormData {
  month: string;
  ownerId: string;
}

type Props = {
  owners: OwnerDto[];
  loading: boolean;
};

export function OwnersReportForm({ owners, loading }: Props) {
  const [form] = Form.useForm<OwnerReportFormData>();

  const month = useWatch('month', form);

  return (
    <ReportFormWrapper>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        // onFinish={handleSubmit}
        // onValuesChange={onValuesChange}
      >
        <FormRow gutter={15}>
          <Col span={6}>
            <FormItem
              label="Месяц"
              name="month"
              rules={[VALIDATION_RULE.REQUIRED]}
            >
              <UiSelect
                placeholder="Выберите месяц"
                options={[
                  {
                    label: 'март 2024',
                    value: '03/24',
                  },
                  {
                    label: 'апрель 2024',
                    value: '04/24',
                  },
                  {
                    label: 'май 2024',
                    value: '05/24',
                  },
                  {
                    label: 'июнь 2024',
                    value: '06/24',
                  },
                  {
                    label: 'июль 2024',
                    value: '07/24',
                  },
                ]}
              />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label="Владелец" name="ownerId">
              <UiSelect
                placeholder="Выберите владельца"
                options={owners.map(({ name, id }) => ({
                  label: name,
                  value: String(id),
                }))}
              />
            </FormItem>
          </Col>
        </FormRow>
        <GetReportButton disabled={!Boolean(month)} spinning={loading} />
      </Form>
    </ReportFormWrapper>
  );
}
