import { useLocation } from 'react-router-dom';

import { NavigationTabs } from 'shared/ui/navigation-tabs';

import { CHARGE_POINT_TABS } from '../../consts';
import { useChargePointFetchingContext } from '../../context';
import { FakeTabs } from '../fake-tabs';

export function ChargePointTabs() {
  const { fetchingTab } = useChargePointFetchingContext();

  const location = useLocation();

  return (
    <NavigationTabs
      tabs={CHARGE_POINT_TABS}
      fetchingTab={fetchingTab}
      rootPath={location.pathname}
      fakeTabs={<FakeTabs />}
    />
  );
}
