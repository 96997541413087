import { Route } from 'react-router-dom';

import { SectionLayout } from 'shared/ui/layout';
import {
  ACQUIRING,
  CONSUME,
  DEBIT,
  PAYMENTS,
  REPORTS,
  OWNERS,
} from 'shared/consts/routes/report';

import { RequireAuth } from 'entities/authorization';
import { ReportLayout } from 'entities/report';

import { ReportsMenu } from 'features/report/menu';

import {
  AcquiringReportPage,
  PaymentsReportPage,
  DebitReportPage,
  ConsumeReportPage,
} from 'pages/report';
import { ConnectedOwnersReport } from 'widgets/report/owners';

export const ReportRoutes = (
  <Route element={<RequireAuth controllerName="Report" rightType="Read" />}>
    <Route path={REPORTS} element={<SectionLayout menu={<ReportsMenu />} />}>
      <Route element={<ReportLayout />}>
        <Route path={ACQUIRING} element={<AcquiringReportPage />} />
        <Route path={CONSUME} element={<ConsumeReportPage />} />
        <Route path={PAYMENTS} element={<PaymentsReportPage />} />
        <Route path={DEBIT} element={<DebitReportPage />} />
        <Route path={OWNERS} element={<ConnectedOwnersReport />} />
      </Route>
    </Route>
  </Route>
);
